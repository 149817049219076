import { AfterContentInit, Component, ContentChild, ElementRef } from '@angular/core';
import { ButtonLinkComponent } from '@components/controls/buttons/button-link/button-link.component';

import { Urls } from '@const/urls';

@Component({
  selector: 'app-update-notification',
  templateUrl: './update-notification.component.html',
  styleUrls: ['./update-notification.component.scss'],
})
export class UpdateNotificationComponent implements AfterContentInit {
  @ContentChild(ButtonLinkComponent, { static: false }) public buttonLink: ElementRef;

  public hasOtherButton = false;

  public ngAfterContentInit(): void {
    this.hasOtherButton = Boolean(this.buttonLink);
  }

  public openAppStore(): void {
    window.open(Urls.appStoreUrl, '_system');
  }
}
