import { Component, Input } from '@angular/core';
import { modalIdNotificationUpdate } from '@const/modals-id.const';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { SetStatusAboutUpdateNotification } from '@store/update-notification/update-notification.action';

@Component({
  selector: 'app-modal-update-notification',
  templateUrl: './modal-update-notification.component.html',
  styleUrls: ['./modal-update-notification.component.scss'],
})
export class ModalUpdateNotificationComponent {
  @Input() public isCritical = false;

  constructor(private store: Store, private modalCtrl: ModalController) {}

  public skipUpdate(): void {
    this.store.dispatch(new SetStatusAboutUpdateNotification(true));
    this.modalCtrl.dismiss(null, null, modalIdNotificationUpdate);
  }
}
